<template>
  <div id="wrapper">
    <div class="loader" v-if="loading"></div>

    <main class="content" v-if="!loading">
      <div class="container">
        <div class="flex-lg-row row flex-column listingDetails">
          <div class="listingDetails__colLeft col-xl-9 col-lg-8">
            <div class="backLink" v-if="prevRoute">
              <a @click="$router.go(-1)"
                ><i class="fas fa-angle-double-left"></i> Back to Properties</a
              >
            </div>
            <div class="backLink" v-else>
              <a :href="$router.push('/')"
                ><i class="fas fa-angle-double-left"></i> Back to Home</a
              >
            </div>

            <agile class="slideshow-container" :slidesToShow="1" :dots="false">
              <div v-if="images.length == 0">
                <img
                  :src="`${$store.state.common.publicPath}img/no_image1.png`"
                  style="width: 100%"
                />
              </div>
              <div
                class="slide"
                v-else
                v-for="(img, imgInx) in images"
                :key="imgInx"
              >
                <div id="extended-img" ref="wrapper">
                  <div class="numbertext">
                    {{ imgInx + 1 }} / {{ images.length }}
                  </div>

                  <div id="spinnerImage">
                    <i
                      class="fas fa-circle-notch fa-spin"
                      style="font-size: 26px; color: #268cab"
                    ></i>
                  </div>
                  <img
                    v-if="!isImageDisplay"
                    ref="image"
                    :src="`${baseAPI}${img.image_name}`"
                  />
                </div>
              </div>
            </agile>
            <div class="listingDetails__facilities">
              <h1>
                {{ property["title"] || "N/A" }}
              </h1>
              <p>
                {{ property["location"] }}
                {{ property["pincode"] ? property["pincode"] : null }}
              </p>
              <p>
                Class type :
                {{
                  property["property_class"] != "" &&
                  property["property_class"] != "null"
                    ? property["property_class"]
                    : "N/A"
                }}
              </p>
              <p v-if="property['area'] != 0 && property['area'] != null">
                Area(in Sq ft) :
                {{
                  property["area"] != 0 && property["area"] != null
                    ? property["area"]
                    : null
                }}
              </p>
              <p v-if="property['floors'] != 0 && property['floors'] != null">
                Floors :
                {{
                  property["floors"] != 0 && property["floors"] != null
                    ? property["floors"]
                    : null
                }}
              </p>
              <p>
                {{
                  property["property_description"] != "" &&
                  property["property_description"] != "null"
                    ? property["property_description"]
                    : "N/A"
                }}
              </p>
              <div class="listingDetails__facilities__group">
                <div class="row" v-if="property['property_video']">
                  <div class="col prop_vid_link">
                    <a
                      class="videoLink"
                      :href="property['video_link']['url']"
                      target="_blank"
                      >Property Video Link</a
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-5 col-lg-8">
                    <div class="listingDetails__facilities__type">Location</div>
                    <google-map
                      :markers="markers"
                      styleData=" height: 300px"
                    ></google-map>
                  </div>
                  <div class="col-xl-4 col-md-6">
                    <div class="listingDetails__facilities__type">
                      Amenities
                    </div>
                    <div class="d-flex flex-wrap w100">
                      <ul class="listingDetails__facilities__list w50">
                        <li v-for="item in showAmenities" :key="item">
                          <!-- <i :class="amenities[item - 1]['icon_name']"></i> -->
                          <i class="fas fa-utensils"></i>
                          {{ amenities[item - 1]["name"] }}
                        </li>
                      </ul>
                      <ul
                        class="listingDetails__facilities__list w50"
                        v-if="amenities.length > 0"
                      >
                        <!-- eslint-disable vue/no-use-v-if-with-v-for -->
                        <li
                          v-for="item in amenities.length"
                          :key="item"
                          v-if="item > showAmenities"
                        >
                          <!-- <i :class="amenities[item - 1]['icon_name']"></i> -->
                          <i class="fas fa-utensil-spoon"></i>
                          {{ amenities[item - 1]["name"] }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6">
                    <div class="listingDetails__facilities__type">Nearby</div>
                    <ul
                      class="listingDetails__facilities__list"
                      v-if="nearByAmenities.length > 0"
                    >
                      <li v-for="(item, index) in nearByAmenities" :key="index">
                        <i :class="item['amenities']['icon_name']"></i>
                        {{ item["amenities"]["name"] }}
                        <span class="colorGray--darker"
                          >({{ item["distance"] }} mi)</span
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="listingDetails__table"
              v-if="addOptions && addOptions.length > 0"
            >
              <div class="table--responsive">
                <table class="table" v-if="additionalOptionData.length > 0">
                  <thead>
                    <tr>
                      <td>Additional Options</td>
                      <td>Basic Layout</td>
                      <td>Standard Layout</td>
                      <td>Premium Layout</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(addOption, index) in additionalOptionData"
                      :key="index"
                    >
                      <td class="align-center">
                        {{ addOption.options.name }}
                      </td>

                      <td v-if="addOption.options.basic == 1">
                        <i class="fas fa-check-circle colorGreen"></i>
                      </td>
                      <td v-else-if="addOption.options.basic">
                        {{ addOption.options.basic }}
                      </td>
                      <td v-else>-</td>

                      <td v-if="addOption.options.standard == 1">
                        <i class="fas fa-check-circle colorGreen"></i>
                      </td>
                      <td v-else-if="addOption.options.standard">
                        {{ addOption.options.standard }}
                      </td>
                      <td v-else>-</td>

                      <td v-if="addOption.options.premium == 1">
                        <i class="fas fa-check-circle colorGreen"></i>
                      </td>
                      <td v-else-if="addOption.options.premium">
                        {{ addOption.options.premium }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="listingDetails__colRight col-xl-3 col-lg-4">
            <div class="widget widget--date">
              <!-- <div class="search__field fieldFloating">
                <div class="d-flex">
                  <datepicker
                    ref="picker"
                    @focusout.native="close"
                    class="formControl"
                    style="border-radius: 0"
                    v-model="start_date"
                  >
                  </datepicker>
                  <button
                    v-if="start_date !== property['available_from']"
                    class="date-reset"
                    @click="
                      start_date = property['available_from'] | getFormatDate
                    "
                  >
                    X
                  </button>
                </div>
                <label class="fieldFloating__label" style="top: 15px"
                  >Start Date</label
                >
              </div> -->
              <div class="fieldFloating fieldDate">
                <datepicker
                  ref="picker"
                  open
                  class="formControl"
                  style="border-radius: 0"
                  v-model="start_date"
                >
                </datepicker>

                <!-- <input
                  type="text"
                  placeholder=" "
                  readonly
                  :value="property['available_from'] | getFormatDate"
                  class="formControl"
                /> -->
                <label class="fieldFloating__label">Start Date</label>
                <!-- <button
                  v-if="start_date !== property['available_from']"
                  class="date-reset"
                  @click="start_date = property['available_from']"
                  style="position: absolute; right: 20px; top: 30px"
                >
                  X
                </button> -->
                <i
                  v-if="start_date == property['available_from']"
                  class="fas fa-calendar-alt"
                ></i>
                <button
                  v-else
                  class="date-reset"
                  @click="start_date = property['available_from']"
                  style="position: absolute; right: 20px; top: 30px"
                >
                  X
                </button>
              </div>
            </div>
            <div class="widget" v-if="contractLengths.length > 0">
              <div class="widget__title">Contract Length</div>
              <div
                class="eRadio"
                v-for="(contL, index) in contractLengths"
                :key="index"
              >
                <input
                  type="radio"
                  v-model="contractLength"
                  :id="'contractLength' + contL['id']"
                  :value="contL"
                />
                <label :for="'contractLength' + contL['id']" class="d-flex">
                  {{
                    contL
                      ? contL["contract_length"]["length"]
                      : null | getLength
                  }}
                  <strong class="mlAuto" v-if="contL.price"
                    >+ ${{ contL["price"] }}</strong
                  >
                </label>
              </div>
            </div>
            <div class="widget">
              <div class="widget__title">
                Layout Design
                <span>(Capacity)</span>
              </div>

              <div
                class="eRadio"
                v-for="(layout, index) in layoutDesigns"
                :key="index"
              >
                <input
                  type="radio"
                  v-model="layoutDesign"
                  :id="'layout' + layout['id']"
                  :value="layout"
                />
                <label :for="'layout' + layout['id']" class="d-flex">
                  {{ layout["layout_design"]["name"] }}
                  <span v-if="layout.capacity">({{ layout.capacity }})</span>
                  <strong class="mlAuto" v-if="layout.price"
                    >+ ${{ layout["price"] }}</strong
                  >
                </label>
              </div>
            </div>
            <div
              class="widget"
              v-if="additionalOptionData && additionalOptionData.length > 0"
            >
              <div class="widget__title">Additional Options</div>
              <div
                class="eCheckbox"
                v-for="item in additionalOptionData.length > 3
                  ? 3
                  : additionalOptionData.length"
                :key="item"
              >
                <input
                  type="checkbox"
                  v-model="add_opt"
                  :value="{
                    name: additionalOptionData[item - 1]['options']['name'],
                    id: additionalOptionData[item - 1]['additional_option_id'],
                    price: additionalOptionData[item - 1]['price'],
                  }"
                  :name="
                    'additionalOption' +
                    additionalOptionData[item - 1]['additional_option_id']
                  "
                  :id="
                    'additionalOption' +
                    additionalOptionData[item - 1]['additional_option_id']
                  "
                />
                <label
                  :for="
                    'additionalOption' +
                    additionalOptionData[item - 1]['additional_option_id']
                  "
                  class="d-flex"
                >
                  {{ additionalOptionData[item - 1]["options"]["name"] }}
                  <strong
                    class="mlAuto"
                    v-if="additionalOptionData[item - 1]['price']"
                    >+ ${{ additionalOptionData[item - 1]["price"] }}</strong
                  >
                </label>
              </div>
              <div class="me" v-if="!isClicked">
                <a
                  href="javascript:;"
                  class="btn--link"
                  title="View All (+4)"
                  v-if="additionalOptionData.length > 3"
                  @click="isClicked = !isClicked"
                  >View All (+{{ additionalOptionData.length - 3 }})</a
                >
              </div>

              <div
                class="eCheckbox"
                v-for="item in additionalOptionData.length"
                :key="item"
                v-if="isClicked && item > 3"
              >
                <input
                  type="checkbox"
                  v-model="add_opt"
                  :value="additionalOptionData[item - 1]['options']['name']"
                  :name="
                    'additionalOption' +
                    additionalOptionData[item - 1]['additional_option_id']
                  "
                  :id="
                    'additionalOption' +
                    additionalOptionData[item - 1]['additional_option_id']
                  "
                />
                <label
                  :for="
                    'additionalOption' +
                    additionalOptionData[item - 1]['additional_option_id']
                  "
                >
                  {{ additionalOptionData[item - 1]["options"]["name"] }}
                  <strong
                    v-if="additionalOptionData[item - 1]['price']"
                    class="mlAuto"
                    >+ ${{ additionalOptionData[item - 1]["price"] }}</strong
                  >
                </label>
              </div>
            </div>
            <div
              class="row widget widget--total d-flex justify-content-between totalPrice"
            >
              <div class="col-md-12">
                <span>Monthly Cost Per Person</span>
                <span style="float: right"
                  >${{ perPersonCost.toFixed(2) }}</span
                >
              </div>
              <div class="col-md-12">
                <span><b>Total Monthly Cost</b></span>
                <span style="float: right">${{ totalCost.toFixed(2) }}</span>
              </div>
              {{ role }}
            </div>
            <button
              class="buttonload btn btn--blue w100"
              style="pointer-events: none"
              v-if="isSubmitting"
            >
              submitting ...
            </button>

            <button
              v-if="!isSubmitting && role == 'admin'"
              class="btn btn--blue w100"
              title="Get Started"
              @click="bookNow"
            >
              Get Started
            </button>
            <button
              class="btn btn--blue w100 contectUs"
              title="Contact us"
              @click="CHANGE_CONTECT_US_POP_UP(true)"
            >
              Contact us
            </button>

            <div class="">
              <button
                class="btn contact-us-link contectUs"
                title="Want more information? Get in touch."
                @click="CHANGE_CONTECT_US_POP_UP(true)"
              >
                Want more information? Get in touch.
              </button>
            </div>
            <div class="">
              <button
                class="btn contact-us-link contectUs"
                title="Broker? See what ExtraSlice has to offer you."
                @click="CHANGE_CONTECT_US_POP_UP(true)"
              >
                Broker? See what ExtraSlice has to offer you.
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// import HeaderComponent from "@/containers/client/Header";
import { getProperty, getCalculation } from "@/api/properties";
import { postContract } from "@/api/contracts";
import { getOptions } from "@/api/options";
import Datepicker from "vuejs-datepicker";
// import autocomplete from "./AutoComplete";
// import VueSlider from "vue-slider-component";
import GoogleMap from "./GoogleMap";
import { mapMutations, mapGetters } from "vuex";
// import carousel from "vue-owl-carousel";
import { VueAgile } from "vue-agile";

export default {
  name: "DetailProperty",
  components: {
    // HeaderComponent,
    Datepicker,
    // autocomplete,
    GoogleMap,
    // carousel,
    agile: VueAgile,
  },
  data() {
    return {
      // isLandscape: false,
      property: null,
      layoutDesign: null,
      // rdbtnLength: null,
      contractLengths: null,
      layoutDesigns: null,
      contractLength: null,
      amenities: null,
      add_opt: [],
      isClicked: false,
      addOptions: null,
      nearByAmenities: [],
      showAmenities: null,
      markers: null,
      loading: true,
      // slideIndex: 1,
      images: [],
      id: null,
      baseAPI: process.env.VUE_APP_BASE_API,
      // finalPrice: 0,
      isSubmitting: false,
      prevRoute: null,
      perPersonCost: 0,
      totalCost: 0,
      start_date: "",
      isImageDisplay: false,
    };
  },
  watch: {
    contractLength() {
      this.getCost();
    },
    layoutDesign() {
      this.getCost();
    },
    add_opt() {
      this.getCost();
    },
    start_date() {
    },
  },
  filters: {
    getLength(data) {
      if (data == 12) {
        return "1 year";
      }
      if (data > 11) {
        return (
          Math.floor(data / 12) +
          " years " +
          (data % 12 > 0 ? (data % 12) + " months" : "")
        );
      } else return data + " months";
    },
    getFormatDate(data) {
      let startDate = data.split("-");
      return startDate[2] + "/" + startDate[1] + "/" + startDate[0];
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  created() {
    this.loading = true;
    this.id = atob(this.$router.history.current.params.id);
    getOptions(1, "all")
      .then((res) => {
        let data = (res.data && res.data.data) || [];
        this.addOptions = data;
      })
      .catch((err) => {
      });
    getProperty(this.id)
      .then((res) => {
        this.loading = false;
        this.isImageDisplay = true;
        this.images = res.data.data.images;
        this.isImageDisplay = false;
        let data = (res.data && res.data.data) || [];
        if (data.is_available == 0) {
          // this.$router.push("/");
        }
        this.property = data;
        this.start_date = this.property.available_from;
        this.contractLengths = data["contract_lengths"];
        this.contractLengths.forEach((contL) => {
          if (contL.is_default) {
            this.contractLength = contL;
          } else {
            this.contractLength = this.contractLengths[0];
          }
        });
        this.layoutDesigns = data["layout_designs"];
        if (this.layoutDesigns.length > 0) {
          this.layoutDesign = this.layoutDesigns[0];
        }
        this.amenities = data["amenities"];
        this.nearByAmenities = data["near_by_amenities"];
        this.additionalOptionData = data["additional_options"];
        this.markers = [
          {
            latitude: data.latitude,
            longitude: data.longitude,
            location: data.longitude,
            price: data.price,
          },
        ];
        this.showAmenities =
          this.nearByAmenities.length < this.amenities.length &&
          this.nearByAmenities.length > 2
            ? this.nearByAmenities.length
            : Math.ceil(this.amenities.length / 2);
        this.getCost();
      })
      .catch((err) => {
        this.isImageDisplay = true;
      });
  },
  mounted() {
    // this.showSlides(this.slideIndex);
    let image = this.$refs.image;
  },
  methods: {
    ...mapMutations("popup", ["CHANGE_CONTECT_US_POP_UP"]),
    getCost() {
      const params = {
        property: this.property,
        contractLength: this.contractLength,
        layoutDesign: this.layoutDesign,
      };
      getCalculation(params)
        .then((res) => {
          if (res.data.data) {
            this.perPersonCost = res.data.data.perPerson;
            this.totalCost = res.data.data.totalPrice;
          }
          if (this.add_opt.length > 0) {
            this.add_opt.forEach((data) => {
              if (data.price) {
                this.totalCost += data.price;
                this.perPersonCost =
                  this.totalCost / this.layoutDesign?.capacity;
              }
            });
          }
        })
        .catch((err) => {
          this.$notify({
            group: "notify",
            type: "error",
            text: err,
          });
        });
    },
    bookNow() {
      if (
        !this.contractLength ||
        !this.layoutDesign ||
        !this.layoutDesign["capacity"]
      ) {
        this.$notify({
          group: "notify",
          type: "error",
          text: "please select any one options from each contract length, layout design",
        });
      } else {
        let conLength = {
          length: this.contractLength["contract_length"]["length"],
          price: this.contractLength["price"],
          id: this.contractLength["contract_length_id"],
        };

        let layoutDesign = {
          name: this.layoutDesign["layout_design"]["name"],
          price: this.layoutDesign["price"],
          id: this.layoutDesign["layout_design_id"],
        };

        if (this.start_date) {
          // debugger;
          let new_date = new Date(this.start_date);
          let year = new_date.getFullYear();
          let date = new_date.getDate();
          let month = new_date.getMonth() + 1;
          this.start_date = year + "-" + month + "-" + date;
          // moment(new Date()).format("DD/MM/YYYY");
          // query.start_date = startDate;
        }

        let data = {
          property_id: this.id,
          start_date: this.start_date,
          contract_length: conLength,
          layout: layoutDesign,
          capacity: this.layoutDesign["capacity"], //this.property["capacity"],
          additionalOptions: this.add_opt,
          final_price: this.totalCost,
          cost_per_person: this.perPersonCost,
          contract_length_price: this.totalCost,
          contract_length_id: this.contractLength.contract_length_id,
          layout_id: this.layoutDesign.layout_design_id,
          layout_price: this.layoutDesign.price,
        };
        this.isSubmitting = true;
        postContract(data)
          .then((res) => {
            this.$router.push("/contract/" + btoa(res.data.data.id));
            this.isSubmitting = false;
          })
          .catch((err) => {
            this.isSubmitting = false;
            this.$notify({
              group: "notify",
              type: "error",
              text: "Something went wrong please try again",
            });
          });
      }
    },
    // plusSlides(n) {
    //   this.showSlides((this.slideIndex += n));
    // },
    // currentSlide(n) {
    //   this.showSlides((this.slideIndex = n));
    // },
    // showSlides(n) {
    //   var i;
    //   var slides = document.getElementsByClassName("mySlides");
    //   var dots = document.getElementsByClassName("dot");
    //   if (n > slides.length) {
    //     this.slideIndex = 1;
    //   }
    //   if (n < 1) {
    //     this.slideIndex = slides.length;
    //   }
    //   for (i = 0; i < slides.length; i++) {
    //     slides[i].style.display = "none";
    //   }
    //   for (i = 0; i < dots.length; i++) {
    //     dots[i].className = dots[i].className.replace(" active", "");
    //   }
    //   if (slides[this.slideIndex - 1]) {
    //     slides[this.slideIndex - 1].style.display = "block";
    //   }
    //   if (dots[this.slideIndex - 1]) {
    //     dots[this.slideIndex - 1].className += " active";
    //   }
    // },
  },
  computed: {
    ...mapGetters(["role"]),
    wrapperRatio() {
      let wrapper = this.$refs.wrapper;
      return wrapper.clientHeight / wrapper.clientWidth;
    },
    // imageRatio() {
    //   let image = this.$refs.image;
    //   return image.naturalHeight / image.naturalWidth;
    // },

    // getPrice() {
    //     let price = 0;
    //     if (this.add_opt.length > 0) {
    //         this.add_opt.forEach((data) => {
    //             if (data.price) price += data.price;
    //         });
    //     }
    //     this.finalPrice =
    //         this.property.price +
    //         (this.contractLength?.price || 0) +
    //         (this.layoutDesign?.price || 0) +
    //         price;
    //     return (
    //         this.property.price +
    //         (this.contractLength?.price || 0) +
    //         (this.layoutDesign?.price || 0) +
    //         price
    //     );
    // },
    // totalPrice() {
    //     let totalPrice = this.getPrice;
    //     if (this.contractLength) {
    //         totalPrice =
    //             totalPrice * this.contractLength.contract_length.length;
    //     }
    //     return totalPrice;
    // },
  },
};
</script>
<style lang="scss" scope>
.vdp-datepicker .vdp-datepicker__calendar {
  width: auto !important;
}

.fieldFloating .vue-slider {
  margin-top: 12px;
  width: calc(100% + 50px) !important;
  position: absolute;
  left: -25px;
  z-index: 9;
  background: #fff;
  min-height: 50px;
  border-radius: 4px !important;
  padding: 15px 20px !important;
  box-sizing: border-box;
}

.align-center {
  text-align: center !important;
}
a {
  cursor: pointer;
}

// .fade {
//   animation-duration: 0.2s !important;
// }
#extended-img {
  overflow: hidden;
  position: relative;
  height: 100%;
  text-align: center;
}
#extended-img img:not(.contain):not(.cover) {
  height: 100%;
}
.slideshow-container {
  .prev,
  .agile__nav-button--prev,
  .next,
  .agile__nav-button--next {
    background-color: black;
    border: 0;
    color: white;
  }
  .agile__nav-button--prev {
    left: 0;
  }
  .agile__nav-button--next {
    right: 0;
  }
}
.listingDetails__colRight {
  .btn:not(.contact-us-link) {
    margin-bottom: 20px;
    @media (min-width: 1921px) {
      margin-bottom: 1.024vw;
    }
  }
}
.videoLink {
  color: blue !important;
}
#spinnerImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.slideshow-container > div > div,
.slideshow-container > div,
.agile__slides,
.agile__slides > div {
  height: 100%;
}

.agile__slide {
  height: 100%;
}
</style>
